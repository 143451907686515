import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {

  constructor(public datePipe: DatePipe) {
  }

  transform(value: number, args?: any): string {
    if (!value) {
      return '';
    }

    const now = new Date();
    const secondsAgo = Math.round((now.getTime() - value) / 1000);

    if (secondsAgo < 60) {
      return 'a few seconds ago';
    }

    if (secondsAgo < 60 * 2) {
      return 'a minute ago';
    }

    if (secondsAgo < 60 * 60) {
      return `${Math.floor(secondsAgo / 60)} minutes ago`;
    }

    if (secondsAgo < 60 * 60 * 2) {
      return 'an hour ago';
    }

    if (secondsAgo < 60 * 60 * 24) {
      return `${Math.floor(secondsAgo / 3600)} hours ago`;
    }

    if (secondsAgo < 60 * 60 * 24 * 2) {
      return `${this.datePipe.transform(value, 'HH:mm')}, a day ago`;
    }

    if (secondsAgo < 60 * 60 * 24 * 7) {
      return `${this.datePipe.transform(value, 'HH:mm')}, ${Math.floor(secondsAgo / 86400)} days ago`;
    }

    return this.datePipe.transform(value, args) || 'N/A';
  }

}
