import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: false,
  selector: 'app-conversation-modal',
  templateUrl: './conversation-modal.component.html',
  styleUrls: ['./conversation-modal.component.css']
})
export class ConversationModalComponent implements OnInit {
  @Input() data: any;
  @Output() submit: EventEmitter<any> = new EventEmitter();
  currentUserId: string = '';
  textbox: string = '';
  showMessage: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
    this.currentUserId = localStorage.getItem('auth-user') ? JSON.parse(localStorage.getItem('auth-user')!).id : undefined;
  }

  ngOnInit(): void {
    if (this.data) {
      this.data.comments.sort((a: any, b: any) => a.createdAt.localeCompare(b.createdAt))
    }
  }

  onSendReplyClick() {
    const payload = {
      claimId: this.data.id,
      comment: this.textbox
    }
    this.submit.emit(payload);

    this.data.comments.push({
      userId: this.currentUserId,
      comment: this.textbox,
      createdAt: new Date().getTime() / 1000,
      updatedAt: new Date().getTime() / 1000,
      user: {
        id: this.currentUserId,
        name: JSON.parse(localStorage.getItem('auth-user')!).name,
        imgUrl: JSON.parse(localStorage.getItem('auth-user')!).imgUrl
      }
    })

    this.showMessage = false;
    this.textbox = '';
  }

  confirm() {
    if (this.textbox.length > 0) {
      this.showMessage = true;
    } else this.activeModal.close();
  }

}
