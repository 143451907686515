import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-information-summary',
  templateUrl: './information-summary.component.html',
  styleUrls: ['./information-summary.component.scss']
})
export class InformationSummaryComponent implements OnInit {

  @Input() version: number = 1;
  @Input() title: string = '';
  @Input() value: string | number | null = '-';
  @Input() unitOfMeasurement: string = '';
  @Input() style: string = 'primary';
  @Input() icon: string = '';
  @Input() showReadMore = false;
  @Output() readMore = new EventEmitter();
  valueDisplay: string = '-';

  constructor() { }

  ngOnInit(): void {
    this.valueDisplay = (this.value || '-').toString();
  }

  onReadMoreClick() {
    this.readMore.emit();
  }

}
