import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignatureDrawModalComponent } from '../signature-draw-modal/signature-draw-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: false,
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.css']
})
export class SignatureModalComponent implements OnInit {

  @Output() onConfirmResult = new EventEmitter();

  preview: any;
  resultFile: File | undefined;
  isSelectedFile = false;
  isDrawSignature = false;
  
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  async onFileSelect(value: any) {
    if(value.target.files[0].size > 2000000){
      this.toastrService.warning(this.translate.instant('formbuilder.max-size-is') + ' ' + 2 + 'MB.');
    } else {
      if (value.target.files[0]) {
        this.isDrawSignature = false;
        this.isSelectedFile = true;
        const file: File = value.target.files[0];
  
        if (file) {
          this.processImagePreview(file);
          this.resultFile = file;
        }
      }
    }
  }

  onDrawSignBtnClick(){
    const modalRef = this.modalService.open(SignatureDrawModalComponent, { size: 'lg', windowClass: 'modal-bg-transparent darken-bg modal-confirm-padding' });
    modalRef.closed.subscribe(e => {
      if (e.status === true) {
        this.isDrawSignature = true;
        this.isSelectedFile = false;
        const file = this.imageUrlToFile(e.data);
        
        if(file){
          this.processImagePreview(file);
          this.resultFile = file;
        }
      }
    })
  }

  onConfirmClick() {
    this.onConfirmResult.emit({status: true, data: this.resultFile});
    this.activeModal.close({status: true, data: this.resultFile});
  }

  onCancelClick() {
    this.onConfirmResult.emit({status: false, data: undefined});
    this.activeModal.close({status: false, data: undefined});
  }

  processImagePreview(file:File){
    this.preview = '';
    let currentFile = file;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.preview = e.target.result;
    };

    reader.readAsDataURL(currentFile);
  }

  imageUrlToFile(data: any) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], 'signature.jpeg', { type: 'image/jpeg' })
    return file;
  }

}
