import { Component, Injector, Input, OnInit } from '@angular/core';
import LogicFlow from '@logicflow/core';
import OnepassStartNode from '../registerNode/OnepassStartNode';
import OnepassTaskNode from '../registerNode/OnepassTaskNode';
import OnepassEndNode from '../registerNode/OnepassEndNode';
import OnepassActionNode from '../registerNode/OnepassActionNode';
import CustomHtml from '../registerNode/CustomHtml';
import { HtmlComponent } from '../registerNode/html/html.component';
import { createCustomElement } from '@angular/elements';
import { StartNodeComponent } from '../registerNode/OnepassStartNode/onepass-start-node.component';
import { TaskNodeComponent } from '../registerNode/OnepassTaskNode/onepass-task-node.component';
import { ActionNodeComponent } from '../registerNode/OnepassActionNode/onepass-action-node.component';
import { EndNodeComponent } from '../registerNode/OnepassEndNode/onepass-end-node.component';

@Component({
  standalone: true,
  selector: 'app-custom-node-panel',
  templateUrl: './custom-node-panel.component.html',
  styleUrls: ['./custom-node-panel.component.css']
})
export class CustomNodePanelComponent implements OnInit {

  @Input() lf!: LogicFlow;
  @Input() isVisible: boolean = true;

  constructor(injector: Injector) {
    const PopupElement = createCustomElement(HtmlComponent, { injector });
    const customElement = customElements.get('custom-element');
    if (!customElement) {
      window.customElements.define('custom-element', PopupElement);
    }

    const startNodeComponent = createCustomElement(StartNodeComponent, { injector });
    const startElement = customElements.get('onepass-start-node');
    if (!startElement) {
      window.customElements.define('onepass-start-node', startNodeComponent);
    }

    const taskNodeComponent = createCustomElement(TaskNodeComponent, { injector });
    const taskElement = customElements.get('onepass-task-node');
    if (!taskElement) {
      window.customElements.define('onepass-task-node', taskNodeComponent);
    }

    const actionNodeComponent = createCustomElement(ActionNodeComponent, { injector });
    const actionElement = customElements.get('onepass-action-node');
    if (!actionElement) {
      window.customElements.define('onepass-action-node', actionNodeComponent);
    }

    const endNodeComponent = createCustomElement(EndNodeComponent, { injector });
    const endElement = customElements.get('onepass-end-node');
    if (!endElement) {
      window.customElements.define('onepass-end-node', endNodeComponent);
    }
  }

  ngOnInit(): void {
    let lf = this.lf;
    lf && lf.on("selection:selected", () => {
      lf.updateEditConfig({
        stopMoveGraph: false,
      });
    });
    //注册节点
    this.registerNode();
  }

  registerNode() {
    let lf = this.lf as LogicFlow;
    lf.register(OnepassStartNode);
    lf.register(OnepassTaskNode);
    lf.register(OnepassEndNode);
    lf.register(OnepassActionNode);
    lf.register(CustomHtml);

    //注册非业务事件，业务事件需要放在上一层
    lf.on("connection:not-allowed", (data) => {
      alert(data.msg);
    });

    lf.on("custom:button-click", (model) => {
      console.log('custom:button-click')
      lf.setProperties(model.id, {
        body: "LogicFlow",
      });
    });
  }

  openSelection() {
    (this.lf as LogicFlow).updateEditConfig({
      stopMoveGraph: true,
    });
  }

  addStartNode() {
    (this.lf as LogicFlow).dnd.startDrag({
      type: "OnepassStartNode",
      text: "",
      properties: { text: "Start" },
    });
  }

  addTaskNode() {
    (this.lf as LogicFlow).dnd.startDrag({
      type: "OnepassTaskNode",
      text: "",
      properties: { text: "Task" },
    });
  }

  addEndNode() {
    (this.lf as LogicFlow).dnd.startDrag({
      type: "OnepassEndNode",
      text: "",
      properties: { text: "End" },
    });
  }

  addActionNode() {
    (this.lf as LogicFlow).dnd.startDrag({
      type: "OnepassActionNode",
      text: "",
      properties: { text: "Action" },
    });
  }

  addHTMLNode() {
    (this.lf as LogicFlow).dnd.startDrag({
      type: "CustomHtml",
      text: "",
      properties: { 
        text: "HTML",
        title: "Title",
        description: "Description"
      },
    });
  }
}
