import { StartNodeComponent } from './OnepassStartNode/onepass-start-node.component';
import { HtmlNode, HtmlNodeModel, h as lfh } from "@logicflow/core";
import { WithProperties } from '@angular/elements';
// 提供节点的dom
class OnepassStartNode extends HtmlNode {

    override setHtml(rootEl: any) {
        const { properties } = this.props.model;
        const el: Element & WithProperties<StartNodeComponent> = document.createElement('onepass-start-node') as any;
        el.className = 'lf-html-wrapper';
        el.text = properties['text'];
        el.context = this;
        rootEl.innerHTML = '';
        rootEl.appendChild(el);
    }
}

class OnepassStartNodeModel extends HtmlNodeModel {

    override getNodeStyle() {
        const style = super.getNodeStyle();
        style.stroke = '#111111';
        style.fill = 'transparent';
        style.strokeWidth = 2;
        return style;
    }

    override initNodeData(data: any) {
        super.initNodeData(data);
        this.text.editable = false

        const width = 120;
        const height = 120;
        this.width = width;
        this.height = height;
        this.anchorsOffset = [
            [width / 2, 0],
            [0, height / 2],
            [-width / 2, 0],
            [0, -height / 2],
        ]

        this.text.x = this.text.x;
        this.text.y = this.text.y + 10;
    }
}

export default {
    type: "OnepassStartNode",
    view: OnepassStartNode,
    model: OnepassStartNodeModel
}