import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jsPDF from 'jspdf';
import { Location } from '@angular/common';
import { PayrollService, PayrollServiceType } from 'src/app/services/hr/payroll/payroll.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/services/utilities/error-handler.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payroll-export-preview',
  templateUrl: './payroll-export-preview.component.html',
  styleUrls: ['./payroll-export-preview.component.css']
})
export class PayrollExportPreviewComponent implements OnInit {

  textStyle: string = "font-family: Arial, Helvetica, sans-serif !important;";

  payrollId:string = '';
  type:string = '';
  format:string = '';

  payrollData: any;
  rows: any = []

  isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private payrollService: PayrollService,
    private _location: Location,
    private translate: TranslateService
  ) {
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.activatedRoute.params?.subscribe((params: any) => {
      if (params.payrollId) this.payrollId = params.payrollId;
      if (params.exportType) this.type = params.exportType;
      if (params.exportFormat) this.format = params.exportFormat;

      this.fetchPayrollDetail();
    })
  }

  async fetchPayrollDetail() {
    this.isLoading = true;
    const req = await this.payrollService.list(PayrollServiceType.PAYROLL, '', this.payrollId);
    req.subscribe({
      next: async (res) => {
        this.payrollData = res.data;
        this.rows = this.payrollData.payslips;

        // https://preserver-of-wisdom.vercel.app/docs/OnepassERP/process-flow/payroll/manage-payroll-flow
        this.payrollData['endDate'] = new Date(this.payrollData.endDate * 1000).setDate(new Date(this.payrollData.endDate * 1000).getDate() - 1) / 1000;

        this.rows.map((row: any) => {
          row['workHoursFormatted'] = this.parseDurationResponse(row.workHours);
          row['overtimeHoursFormatted'] = this.parseDurationResponse(row.overtimeHours);
          row['totalHours'] = parseInt(row.workHours.split(':')[0]) + parseInt(row.overtimeHours.split(':')[0]);
          row['name'] = row.user.name;
        })

        console.log(this.rows);

        // this.calculatePayrollStats();
        this.isLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandlerService.handleHttpError(err);
        this.isLoading = false;
      }
    })
  }

  onExportClick() {
    const pdfContent = document.querySelector("#export") as HTMLElement;

    const doc = new jsPDF("portrait", "pt", "a4");
    doc.html(pdfContent,
      {
        margin: 20,
        html2canvas: {
          scale: 0.67,
        }
      })
      .then(() => {
        doc.save('Payroll' + '.pdf');
      });
  }

  onBackClick() {
    this._location.back();
    // window.location.reload();
  }

  parseDurationResponse(duration: string) {
    let res = '';
    let hours = duration.split(':')[0] || '0';
    let minutes = duration.split(':')[1] || '0';
    res += hours + ' Hour(s)';

    return res;
  }

}
