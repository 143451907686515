import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
@Component({
  standalone: false,
  selector: 'app-end-node',
  templateUrl: './onepass-end-node.component.html'
})
export class EndNodeComponent implements OnInit, OnChanges {

  @Input() context:any;
  @Input()
  get text(): string { return this.htmlText; }
  set text(text: string) {
    this.htmlText = text;
  }

  htmlText:string = '';
  isEdit: boolean = false;

  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.text){
      this.onTextChange();
    }
  }

  onTitleClick(){
    if(!this.isEdit) this.onTextChange();
    else this.isEdit = false;

    this.context.props.graphModel.eventCenter.emit("custom:onTitleClick", {data: ''});
  }

  /**
   * The `onTextChange` function in TypeScript sets a flag to true when text is changed, and then
   * resets it to false after a delay of 1000 milliseconds if it was not in edit mode.
   */
  onTextChange(){
    if(!this.isEdit){
      this.isEdit = true;
      setTimeout(() => { this.isEdit = false }, 1000);
    }
  }

}
