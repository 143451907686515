import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

@Component({
  standalone: false,
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit, OnChanges {

  @Input() style: string = "";                // differentiate form style of filter and inside modal form
  @Input() attribute: string = "name";        // attribute used to display as dropdown value
  @Input() placeholder: string = "Employee";  // label when form is not touched yet
  @Input() data: any = [];                    // data where dropdown value come from
  @Input() defaultValue: string = "";         // used to set default value when component is loaded
  @Input() disabled: boolean = false;
  @Input() class: string = "";
  @Output() onItemSelect = new EventEmitter();

  containerClass: string = '';
  inputClass: string = '';
  spanClass: string = '';

  autocompleteCtrl = new FormControl({ value: '', disabled: this.disabled });
  valueBackup:any;
  list!: Observable<any[]>;

  constructor() { }

  ngOnInit(): void {
    // initiate autocomplete dropdown value
    this.list = this.autocompleteCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    if (this.class.length === 0) {
      if (this.style === 'modal-form') {
        this.containerClass = 'input-group';
        this.inputClass = 'form-control';
        this.spanClass = 'input-group-text px-2 cursor-pointer';
      }
      else {
        this.containerClass = 'input-group bg-light rounded-pill';
        this.inputClass = 'form-select bg-light rounded-pill-start';
        this.spanClass = 'input-group-text px-2 bg-light rounded-pill-end cursor-pointer';
      }
    } else {
      this.containerClass = 'input-group bg-light rounded-pill';
      this.inputClass = this.class;
      this.spanClass = 'input-group-text bg-light px-2 border-start-0 cursor-pointer';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // re-initiate autocomplete dropdown value when data is changed
      this.data = changes.data.currentValue;

      this.list = this.autocompleteCtrl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );

      if (this.defaultValue && this.defaultValue.length > 0) {
        const find = this.data.find((item: any) => item.id === this.defaultValue);
        if (find) this.autocompleteCtrl.patchValue(find[this.attribute]);
      }
    }

    if (changes.disabled) {
      // reset the field and its disabled status
      this.autocompleteCtrl = new FormControl({ value: '', disabled: this.disabled });
    }

    if (changes.defaultValue) {
      if (this.defaultValue?.length > 0) {
        const find = this.data.find((item: any) => item.id === this.defaultValue);
        if (find) this.autocompleteCtrl.patchValue(find[this.attribute]);
      }
    }

    if (changes.value) this.resetFilter();
  }

  /**
   * The function filters an array of objects based on a specific attribute value.
   * @param {string} value - The `value` parameter is a string that represents the value to filter the
   * data by.
   * @returns a filtered array based on the provided value.
   */
  private _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.data.filter((option: any) => option[this.attribute].toLowerCase().includes(filterValue));
  }

  /**
   * The onItemSelected function emits the value of the autocomplete control when an item is selected.
   * @param {any} data - The parameter "data" is of type "any", which means it can accept any data
   * type. It is used as an argument for the "onItemSelected" function.
   */
  onItemSelected(data: any) {
    this.onItemSelect.emit(this.autocompleteCtrl.value);
    this.valueBackup = this.autocompleteCtrl.value;
  }

  /**
   * The function "resetFilter" resets the autocomplete control and emits an empty string when an item
   * is selected.
   */
  resetFilter(options:string = 'emit') {
    this.autocompleteCtrl.reset()
    if(options === 'emit') this.valueBackup = null;
    if(options === 'emit') this.onItemSelect.emit('');
  }

  resetValue(){
    setTimeout(() => {
      this.autocompleteCtrl.setValue(this.valueBackup);
    }, 200);
  }

}
