import { Component, HostListener, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import SignaturePad from 'signature_pad';

@Component({
  standalone: false,
  selector: 'app-signature-draw-modal',
  templateUrl: './signature-draw-modal.component.html',
  styleUrls: ['./signature-draw-modal.component.css']
})
export class SignatureDrawModalComponent implements OnInit {
  signPad:any;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.setupSignaturePad();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setupSignaturePad();
  }

  setupSignaturePad(){
    const canvas:any = document.querySelector("canvas");
    var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);

    this.signPad = new SignaturePad(canvas, {
      // It's Necessary to use an opaque color when saving image as JPEG;
      // this option can be omitted if only saving as PNG or SVG
      backgroundColor: 'rgb(240, 240, 240)'
    });
    this.signPad.addEventListener("beginStroke", () => {
      // console.log("Signature started");
    }, { once: true });

    // This part causes the canvas to be cleared
    this.signPad.clear();
  }

  onConfirmClick() {
    const signature = this.signPad.toDataURL("image/jpeg");
    this.activeModal.close({status: true, data: signature});
  }

  onCancelClick() {
    this.activeModal.close({status: false, data: null});
  }

}
