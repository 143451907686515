@if(isVisible){
    <div class="node-panel">
        <div class="node-item" (mousedown)="openSelection()">
            <div class="node-item-icon selection"></div>
            <span class="node-label">Select</span>
        </div>
    
        <div class="node-item" (mousedown)="addStartNode()">
            <div class="node-item-icon onepass-start-node"></div>
        </div>
    
        <div class="node-item" (mousedown)="addEndNode()">
            <div class="node-item-icon onepass-end-node"></div>
        </div>
    
        <div class="node-item" (mousedown)="addActionNode()">
            <div class="node-item-icon onepass-action-node"></div>
        </div>
    
        <div class="node-item" (mousedown)="addTaskNode()">
            <div class="node-item-icon onepass-task-node"></div>
        </div>
    
        <!-- <div class="node-item" (mousedown)="addStartNode()">
            <div class="node-item-icon start"></div>
            <span class="node-label">Start</span>
        </div> -->
        <!-- <div class="node-item" (mousedown)="addGeneralNode()">
            <div class="node-item-icon general"></div>
            <span class="node-label">Task</span>
        </div> -->
        <!-- <div class="node-item" (mousedown)="addConditionNode()">
            <div class="node-item-icon condition"></div>
            <span class="node-label">Condition</span>
        </div> -->
        <!-- <div class="node-item" (mousedown)="addHTMLNode()">
            <div class="node-item-icon html"></div>
            <span class="node-label">Html</span>
        </div> -->
        <!-- <div class="node-item" (mousedown)="addEndNode()">
            <div class="node-item-icon end"></div>
            <span class="node-label">End</span>
        </div> -->
    </div>
}