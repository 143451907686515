import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: false,
  selector: 'app-file-viewer-modal',
  templateUrl: './file-viewer-modal.component.html',
  styleUrls: ['./file-viewer-modal.component.css']
})
export class FileViewerModalComponent implements OnInit, OnChanges {
  filePreview: any;
  fileUrl: any;
  fileExt = '';
  rotate: any;
  isFullScreenImage: boolean = false;

  public cancelBtnLabel: string = 'others.close';

  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if(this.fileUrl){
      this.fileExt = this.fileUrl.split('.').pop().toLowerCase();
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
      this.filePreview = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.filePreview){
      this.filePreview = changes.file.currentValue;
      this.fileUrl = '';
    }
  }

  ngOnDestroy() {
    this.onCancel();
  }

  public onCancel(): void {
    // NOTE: closing modal shows console error, currently no idea how to fix it
    this.activeModal.close();
  }
}
