import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../services/shared/navigation.service';

@Component({
  standalone: false,
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  currentSideBarOption: string = ""
  constructor(
    private navigation: NavigationService,
    private router: Router
  ) { }
  currentSystemForNavbar: string = ""

  selectedSystemForNavbar(event: string) {
    // this.currentSystemForNavbar = event
    // console.log(this.currentSystemForNavbar);
  }
  systemOptions: any

  getData() {
    this.systemOptions =
      [
        {
          img1: "/assets/icons/dark-dashboard/dark-dashboard.png",
          img2: "/assets/icons/white-dashboard/white-dashboard.png",
          routerLink: "/admin/dashboard",
          linkText: "Dashboard"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-people.png",
          img2: "/assets/icons/white-dashboard/white-people.png",
          routerLink: "/admin/management",
          linkText: "Management"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-accounts.png",
          img2: "/assets/icons/white-dashboard/white-accounts.png",
          routerLink: "/admin/accounts",
          linkText: "Accounts"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-claims.png",
          img2: "/assets/icons/white-dashboard/white-claims.png",
          routerLink: "../admin/claims",
          linkText: "Claims"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-clocking.png",
          img2: "/assets/icons/white-dashboard/white-clocking.png",
          routerLink: "../admin/clocking",
          linkText: "Clocking"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-payroll.png",
          img2: "/assets/icons/white-dashboard/white-payroll.png",
          routerLink: "../admin/payroll",
          linkText: "Payroll"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-leave.png",
          img2: "/assets/icons/white-dashboard/white-leave.png",
          routerLink: "../admin/leave",
          linkText: "Leave"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-learning.png",
          img2: "/assets/icons/white-dashboard/white-learning.png",
          routerLink: "../admin/learning",
          linkText: "Learning"
        },
        {
          img1: "/assets/icons/dark-dashboard/dark-learning.png",
          img2: "/assets/icons/white-dashboard/white-learning.png",
          routerLink: "../learning",
          linkText: "Learning (User)"
        },
      ]
  }

  ngOnInit(): void {

    this.getData()
    this.navigation.getNavChangesA().subscribe((data: any) => {
      this.currentSideBarOption = data
      // console.log(this.currentSideBarOption,'received in app component');
      // if (this.currentSideBarOption == 'Learning (User)'){
      //   this.router.navigate(["learning","my-learning"] )
      // }
      // else {
      //   this.router.navigate(["admin",this.currentSideBarOption.toLowerCase()] )
      // }

    })
  }

}
