<div class="container p-4 mt-4">
  <div id="overview-content" class="position-relative">

    @if (isLoading) {
      <app-loading-indicator></app-loading-indicator>
    }

    @if (!isLoading) {
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="row mb-4" style="min-width: 210mm;">
            <div class="d-flex justify-content-between">
              <button class="btn btn-default" (click)="onBackClick()">
                <i class="bi-arrow-left"></i> {{'others.back' | translate | titlecase}}
              </button>
              <button (click)="onExportClick()" class="btn btn-primary p-3">
                <i class="bi-download me-2"></i>
                {{'payroll.save-payslip' | translate}}
              </button>
            </div>
          </div>
          <div class="rounded p-2 pt-3 w-100">
            <div id="export" [style]="textStyle">
              <div class="row my-3 mb-5 mx-0">
                <div class="col">
                  <div class="p-3 bg-primary"
                    style="width: 120px;">
                    <img [src]="'../../../../../assets/logos/logo_white.png'"
                      class="img-fluid ps-3">
                  </div>
                </div>
                <div class="col">
                  <div class="btn-group float-end mt-3">
                    <button [style]="textStyle"
                      class="btn border-primary bg-white bg-info2 cursor-default pt-0 fs-7">
                      {{'claims.date' | translate}}
                    </button>
                    <button [style]="textStyle"
                      class="btn border-primary bg-white fw-bold cursor-default pt-0 fs-7">
                      {{ payrollData.payDate * 1000 | date: 'dd MMMM yyyy'}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row my-3 mx-0">
                <h2 [style]="textStyle" class="text-center fw-bold">
                  {{'payroll.payslip' | translate | uppercase}}
                  {{ payrollData.payDate * 1000 | date: 'MMMM yyyy' | uppercase}}
                </h2>
              </div>
              <div class="row my-4 mx-0">
                <div class="col">
                  <table class="w-100 table-striped-payslip">
                    <thead>
                      <tr class="bg-primary">
                        <th [style]="textStyle" class="fw-bold text-center text-white p-3">
                          {{'others.table-number' | translate}}
                        </th>
                        <th [style]="textStyle" class="fw-bold text-center text-white p-3">
                          {{'schedule.name' | translate}}
                        </th>
                        <th [style]="textStyle" class="fw-bold text-center text-white p-3">
                          {{'schedule.department' | translate}} - {{'schedule.role' | translate}}
                        </th>
                        <th [style]="textStyle" class="fw-bold text-center text-white p-3">
                          {{'payroll.payslip-number' | translate}}
                        </th>
                        <th [style]="textStyle" class="fw-bold text-center text-white p-3">
                          {{'payroll.value' | translate}}
                        </th>
                        <th [style]="textStyle" class="fw-bold text-center text-white p-3">
                          {{'payroll.working-hours' | translate}}
                        </th>
                        <th [style]="textStyle" class="fw-bold text-center text-white p-3">
                          {{'payroll.overtime' | translate}}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      @for (item of payrollData.payslips; track item; let i = $index) {
                        <tr>
                          <td [width]="'50px'" class="ps-3 align-middle">
                            {{i + 1}}
                          </td>
                          <td [style]="textStyle" class="py-3 align-middle" [width]="'240px'">
                            {{ item.name || ''}}
                          </td>
                          <td [style]="textStyle" class="py-3 align-middle">
                            {{ item.user?.departmentName || 'N/A' }} - {{ item.user?.roleName || 'N/A'  }}
                          </td>
                          <td [style]="textStyle" class="py-3 align-middle">
                            {{ (item.id.split('-')[0] || 'N/A').toUpperCase() }}
                          </td>
                          <td [style]="textStyle" class="py-3 align-middle fw-bold">
                            {{ item.totalSalary | currency}}
                          </td>
                          <td [style]="textStyle" class="py-3 align-middle">
                            {{item.workHoursFormatted || '-'}}
                          </td>
                          <td [style]="textStyle" class="py-3 align-middle">
                            {{item.overtimeHoursFormatted || '-'}}
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="d-flex flex-column justify-content-between">
                <div class="row mx-2">
                  <div class="col d-flex">
                    <p class="fs-6" [style]="textStyle">
                      {{'payroll.footer-text-1' | translate}}
                    </p>
                    <p class="fs-6 fw-bold ms-2" [style]="textStyle">
                      {{'superink@hr.sg'}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }

  </div>
</div>