<div class="container p-4 mt-4">
  <div id="overview-content" class="position-relative">

    @if (isLoading) {
      <app-loading-indicator></app-loading-indicator>
    }

    @if (!isLoading) {
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="row mb-4">
            <div class="d-flex justify-content-between">
              <button class="btn btn-default" (click)="onBackClick()">
                <i class="bi-arrow-left"></i> {{'others.back' | translate}}
              </button>
              <button (click)="onExportClick()" class="btn btn-primary">
                <i class="bi-download"></i>
                {{'clocking.save-site-qr' | translate}}
              </button>
            </div>
          </div>
          @if (siteData) {
            <div class="border rounded p-2 pt-3">
              <div id="export" [style]="textStyle">
                <div class="d-flex">
                  <qrcode
                    #qrCode
                    [qrdata]="siteData.id || '-'"
                    [width]="150"
                    [elementType]="elementType"
                  [errorCorrectionLevel]="'M'"></qrcode>
                  <div class="d-flex flex-column mt-2">
                    <div>
                      <p class="mb-1 mt-0" [style]="textStyle">{{'clocking.site-name' | translate}}:</p>
                      <p class="fs-7 fw-bold" [style]="textStyle">{{siteData.title || 'N/A'}}</p>
                    </div>
                    <div>
                      <p class="mb-1 mt-0" [style]="textStyle">{{'clocking.site-detail' | translate}}:</p>
                      <p class="fs-7 fw-bold" [style]="textStyle">{{siteData.summary || 'N/A'}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    }

  </div>
</div>

