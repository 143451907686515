import { Component, OnInit, Input } from '@angular/core';
@Component({
  standalone: false,
  selector: 'app-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.css']
})
export class HtmlComponent implements OnInit {

  @Input() context:any;
  @Input()
  get text(): string { return this.htmlText; }
  set text(text: string) {
    this.htmlText = text;
  }

  htmlText:string = '';
  isEdit:boolean = false;

  constructor() {

  }

  ngOnInit(): void {

  }

  onTitleClick(){
    // this.isEdit = true;
    this.context.props.graphModel.eventCenter.emit("custom:onTitleClick", {data: ''});
  }

}
