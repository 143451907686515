import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Map } from 'mapbox-gl';

declare let google: any;

@Component({
  standalone: false,
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  lat: number = 1.356954;
  lng: number = 103.761592;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.use('en');
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }
}
