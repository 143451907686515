import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: false,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  fullscreenRoutes: string[] = ['templates/builder', 'workflows/builder', 'forms/create-form'];
  style: string = '';

  constructor(
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.router.events.subscribe((event:any) => {
      if(event.type === 1){
        if(this.fullscreenRoutes.some(v => event?.url?.includes(v))){
          this.style = 'left: 0px !important; width: 100%;';
        } else {
          this.style = '';
        }
      }
    });
  }
}
