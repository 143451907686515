import { HtmlNode, HtmlNodeModel, h as lfh } from "@logicflow/core";
import { HtmlComponent } from './html/html.component';
import { NgElement, WithProperties } from '@angular/elements';

class CustomHtmlNode extends HtmlNode {

    override setHtml(rootEl: any) {
        const thiss = this;
        const { properties } = this.props.model;
        const el: NgElement & WithProperties<HtmlComponent> = document.createElement('custom-element') as any;
        el.className = 'lf-html-wrapper';
        el.text = properties['title'];
        el.context = this;
        // const html = 
        // `
        //     <div class="custom-html d-flex align-items-center justify-content-center border border-2 border-dark rounded"
        //         style="overflow-y: none; height: 100px;">
        //         <div class="custom-body" style="overflow: hidden">
        //             <p style="max-width:80px;"
        //                 class="p-1 mb-0 fw-bold text-primary"
        //                 onclick="onTitleClick()">
        //                 ${properties['title']}
        //             </p>
        //         </div>
        //     </div>
        // `
        // el.innerHTML = html;

        // @ts-ignore
        // window.onTitleClick = () => {
        //     thiss.props.graphModel.eventCenter.emit("custom:onTitleClick", {data: ''});
        // }

        rootEl.innerHTML = '';
        rootEl.appendChild(el);
    }


    /* 纯HTML*/
    /*
    override setHtml(rootEl: any) {
        const { properties } = this.props.model;
        const el = document.createElement('div');
        el.className = 'lf-html-wrapper';
        const html = `
                        <div class="custom-html">
                            <div class="custom-body" style="position: relative;">
                                <p style="max-width:80px;"
                                    class="p-2 fw-bold text-primary position-absolute">
                                    ${properties['text']}
                                </p>
                                <p style="max-width:80px;"
                                    class="p-1 mb-0 text-success">
                                    ${properties['description']}
                                </p>
                                <div style="height: 100px; width: 120px; object-fit: contain; object-position: center;">
                                    <img class="img img-fluid" 
                                        style="height: 100px; object-fit: contain" 
                                        src="https://picsum.photos/120/100">
                                </div>
                            </div>
                        </div>
                    `
        el.innerHTML = html;
        rootEl.innerHTML = '';
        rootEl.appendChild(el);
    }
    */
}

class CustomHtmlNodeModel extends HtmlNodeModel {

    override getNodeStyle() {
        const style = super.getNodeStyle();
        style.stroke = '#2987ff';
        style.fill = 'transparent';
        style.strokeWidth = 2;
        return style;
    }

    override initNodeData(data: any) {
        super.initNodeData(data);
        data.text.editable = false;
        this.text.editable = false
        const width = 100;
        const height = 100;
        this.width = width;
        this.height = height;
        this.anchorsOffset = [
            [width / 2, 0],
            [0, height / 2],
            [-width / 2, 0],
            [0, -height / 2],
        ]

        this.text.x = this.text.x;
        this.text.y = this.text.y;

        // console.log(this.graphModel)
    }

    // onBtnClick(data:any){
    //     this.graphModel.eventCenter.emit("custom:onBtnClick", data);
    // }
}

export default {
    type: "CustomHtml",
    view: CustomHtmlNode,
    model: CustomHtmlNodeModel
}