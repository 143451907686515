import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import LogicFlow from '@logicflow/core';
import { BpmnElement, Snapshot, Menu, SelectionSelect, Control } from '@logicflow/extension';
import customEdge from 'src/app/home/module-research/logicflow/customEdge';
import CustomHtml from 'src/app/home/module-research/logicflow/registerNode/CustomHtml';
import OnepassActionNode from 'src/app/home/module-research/logicflow/registerNode/OnepassActionNode';
import OnepassEndNode from 'src/app/home/module-research/logicflow/registerNode/OnepassEndNode';
import OnepassStartNode from 'src/app/home/module-research/logicflow/registerNode/OnepassStartNode';
import OnepassTaskNode from 'src/app/home/module-research/logicflow/registerNode/OnepassTaskNode';

@Component({
  standalone: false,
  selector: 'app-workflow-viewer',
  templateUrl: './workflow-viewer.component.html',
  styleUrls: ['./workflow-viewer.component.css']
})
export class WorkflowViewerComponent implements OnInit, OnChanges {
  @Input() workflow!:any;

  lf: any = null;
  @ViewChild('flowContainer', { static: true }) flowContainer: any;
  
  constructor() { }

  ngOnInit(): void {
    this.setupLogicFlow();

    setTimeout(() => {
      this.importDiagram(this.workflow);
    }, 500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.workflow){
      setTimeout(() => {
        this.importDiagram(this.workflow);
      }, 500);
    }
  }

  setupLogicFlow(){
    LogicFlow.use(BpmnElement);
    LogicFlow.use(Snapshot);
    LogicFlow.use(Control);
    LogicFlow.use(Menu);
    LogicFlow.use(SelectionSelect);
    this.lf = new LogicFlow({
      container: this.flowContainer.nativeElement,
      grid: true,
      height: 600,
      keyboard: {
        enabled: false,
      },
      isSilentMode: true
    });

    this.lf.register(OnepassStartNode);
    this.lf.register(OnepassTaskNode);
    this.lf.register(OnepassEndNode);
    this.lf.register(OnepassActionNode);
    this.lf.register(CustomHtml);
    this.lf.register(customEdge);

    this.lf.setDefaultEdgeType('custom-edge');
    this.lf.setTheme({
      polyline: {
        stroke: '#666666ff',
        hoverStroke: '#666666ff',
        selectedStroke: '#666666ff',
        outlineColor: '#666666ff',
        strokeWidth: 2
      },
      nodeText: {
        color: '#222222'
      },
      edgeText: {
        textWidth: 100,
        overflowMode: 'autoWrap',
        color: '#222222',
        background: {
          fill: '#f7f9ff'
        }
      },
    })

    const context = this;
    this.lf.extension.menu.setMenuConfig({
      nodeMenu: [
        {
          text: "Delete",
          callback(node:any) {
            context.lf.deleteNode(node.id);
          },
        },
      ],
      edgeMenu: [
        {
          text: "Delete",
          callback(edge:any) {
            context.lf.deleteEdge(edge.id);
          },
        },
      ]
    });
    this.onUpdateControlConfig();

    this.lf.render({});
  }

  private importDiagram(data: any) {
    (this.lf as LogicFlow).render(data);
  }

  onUpdateControlConfig(){
    const ControlMenuItems = [
      {
        key: 'zoom-out',
        iconClass: 'lf-control-zoomOut',
        title: 'Zoom Out',
        text: 'Zoom Out',
        onClick: () => {
          this.lf.zoom(false)
        },
      },
      {
        key: 'zoom-in',
        iconClass: 'lf-control-zoomIn',
        title: 'Zoom In',
        text: 'Zoom In',
        onClick: () => {
          this.lf.zoom(true)
        },
      },
      {
        key: 'reset',
        iconClass: 'lf-control-fit',
        title: 'Reset Zoom',
        text: 'Reset Zoom',
        onClick: () => {
          this.lf.resetZoom()
        },
      },
      {
        key: 'undo',
        iconClass: 'lf-control-undo',
        title: 'Undo',
        text: 'Undo',
        onClick: () => {
          this.lf.undo()
        },
      },
      {
        key: 'redo',
        iconClass: 'lf-control-redo',
        title: 'Redo',
        text: 'Redo',
        onClick: () => {
          this.lf.redo()
        },
      },
    ]

    ControlMenuItems.forEach((item:any) => {
      this.lf.extension.control.removeItem(item.key);
      this.lf.extension.control.addItem(item)
    })
  }

}
