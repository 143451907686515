<div (click)="onOptionClick()">
  @if (optionInfo?.children?.length > 0) {
    <div class="sidebar-parent">
      <div class="sidebar-text fs-6 w-100 text-start" style="display: block;">
        @if (!optionInfo.img1.includes('png') && !optionInfo.img1.includes('svg')) {
          <i [class]="optionInfo.img1 + ' me-3'"></i>
        } @else {
          <img [ngClass]="clickedOption == optionInfo.linkText ? 'sidebar-iconsToggle me-3' : 'sidebar-icons me-3'" src="{{optionInfo.img1}}">
        }
        {{optionInfo.linkText | translate}}
      </div>
    </div>
  }

  @if (optionInfo?.children?.length === 0) {
    @if (optionInfo.routerLink === '#back') {
      <div class="sidebar-parent">
        <div class="sidebar-text fs-6 w-100 text-start" style="display: block;">
          {{optionInfo.linkText | translate}}
        </div>
      </div>
    }
    @if (optionInfo.routerLink !== '#back') {
      <a [routerLink]="optionInfo.routerLink"
        routerLinkActive="active" class="fs-6 w-100 text-start" style="display: block;">
        @if (!optionInfo.img1.includes('png') && !optionInfo.img1.includes('svg')) {
          <i [class]="optionInfo.img1 + ' me-3'"></i>
        } @else {
          <img [ngClass]="clickedOption == optionInfo.linkText ? 'sidebar-iconsToggle me-3' : 'sidebar-icons me-3'" src="{{optionInfo.img1}}">
        }
        {{optionInfo.linkText | translate}}
      </a>
    }
  }
</div>


