import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'timeago2'
})
export class Timeago2Pipe implements PipeTransform {

  constructor(public datePipe: DatePipe) {
  }

  transform(value: number, args?: any): string {
    if (!value) {
      return '';
    }

    const now = new Date();
    const secondsAgo = Math.round((now.getTime() - value) / 1000);

    if (secondsAgo < 60 * 60 * 24) {
      return `${this.datePipe.transform(value, 'HH:mm')}`;
    }

    if (secondsAgo < 60 * 60 * 24 * 2) {
      return `Yesterday`;
    }

    if (secondsAgo < 60 * 60 * 24 * 7) {
      return `${Math.floor(secondsAgo / 86400)} days ago`;
    }

    return this.datePipe.transform(value, args) || 'N/A';
  }

}
