<div class="custom-html">
    <div class="node-graphic">
        <img class="img img-fluid"
            draggable="false" 
            style="height: 120px; object-fit: contain" 
            src="../../../../../assets/workflow/Start-Node.svg">
    </div>
    <div class="node-body pt-4">
        <p class="node-text p-1 mt-3 mb-0"
            [ngStyle]="{'border': isEdit ? '1px dashed grey' : ''}"
            (click)="onTitleClick()">
            {{htmlText}}
        </p>
    </div>
</div>