<div class="bottom-bar">
  <div class="list-container rounded border shadow p-3" [ngClass]="{'closed': !isOpen, 'open': isOpen}">
    <div class="row mb-1">
      <div class="col d-flex justify-content-between" (click)="setOpen(isOpen)">
        <h3 class="fw-bold">
          @if (unreadCount > 0) {
            <span class="badge rounded-pill bg-primary">{{unreadCount}}</span>
          }
          Messaging
        </h3>
        @if (isOpen) {
          <i class="bi bi-chevron-down"></i>
        }
        @if (!isOpen) {
          <i class="bi bi-chevron-up"></i>
        }
      </div>
    </div>

    <div class="row mb-2">
      <div class="col">
        <input
          type="text"
          class="w-100 rounded-pill px-3 py-3 bg-light border-0"
          placeholder="Search"
          [(ngModel)]="searchInput"
          (keyup)="doSearch()">
      </div>
    </div>

    <div class="row chat-list mb-2">
      @for (item of chatList; track item) {
        <div
          [ngClass]="{'selected': selectedChat === item}"
          (click)="setSelectedChat(item)"
          class="col-12 d-flex justify-content-between align-items-center mb-1" style="padding:10px">
          <div class="d-flex">
            <img [src]="item.imgUrl"
              [default]="'../../../../../assets/identicon.png'"
              alt="" class="rounded-circle me-3"
              style="width: 44px; height: 44px;"/>
            <div class="d-flex flex-column">
              <span class="fw-bold fs-6 mb-1">{{item.name}}</span>
              <span class="fs-7 text-muted">
                {{item.previewMessage.length > 30 ? item.previewMessage.substring(0, 30) + '...' : item.previewMessage}}
              </span>
            </div>
          </div>
          <span class="fs-8 text-muted">{{item.timestamp * 1000 | timeago2}}</span>
        </div>
      }
    </div>
  </div>

  @if (selectedChat) {
    <div class="chat-container rounded border shadow pt-3 px-3">
      <div class="row chat-header pb-2">
        <div class="col-12 d-flex align-items-start justify-content-between">
          <div class="d-flex align-items-center">
            <img [src]="selectedChat.imgUrl"
              [default]="'../../../../../assets/identicon.png'"
              alt="" class="rounded-circle me-3"
              style="width: 44px; height: 44px;"/>
            <div class="d-flex flex-column">
              <span class="fw-bold fs-6 mb-1">{{selectedChat.name}}</span>
              <span class="fs-7 text-muted">{{selectedChat.timestamp * 1000 | timeago}}</span>
            </div>
          </div>
          <i class="bi bi-x fs-4 cursor-pointer" (click)="selectedChat=null"></i>
        </div>
      </div>
      <div class="row chat-content p-2" id="chatContent">
        <div class="col d-flex flex-column-reverse">
          @for (message of conversations; track message) {
            @if (message.user?.id !== currentUserId) {
              <div class="col-12 d-flex mb-3">
                <img
                  [src]="message.user?.imgUrl"
                  [default]="'../../../../../assets/identicon.png'"
                  alt="" class="profile-pic position-relative me-1">
                <div class="rounded bg-info2 p-3 d-flex flex-row justify-content-between align-items-end" style="max-width: 80%;">
                  <span class="me-3">{{ message.text}}</span>
                  <span class="fs-7 text-mute"> {{message.createdAt * 1000 | date:'HH:mm'}}</span>
                </div>
              </div>
            } @else {
              <div class="col-12 d-flex flex-row-reverse mb-3">
                <div class="rounded bg-success2 p-3" style="max-width: 80%;">
                  <div class="d-flex flex-row justify-content-between align-items-end">
                    <span class="me-3 text-end">{{ message.text}}</span>
                    <span class="fs-7 text-mute"> {{message.createdAt * 1000 | date:'HH:mm'}}</span>
                  </div>
                </div>
              </div>
            }
          }
        </div>
      </div>
      <div class="row chat-footer pb-3">
        <div class="col-md-12 mt-3 d-flex flex-row">
          <button (click)="onSendReplyClick()"
            class="btn btn-success btn-sm rounded-circle me-2 border-0 p-1 px-2">
            <i class="bi-paperclip fs-4"></i>
          </button>
          <input
            type="text"
            class="form-control bg-light border-0"
            placeholder="Write a message ..."
            [(ngModel)]="textbox">
          <button (click)="onSendReplyClick()"
            class="btn btn-primary btn-sm rounded-circle ms-2 p-1 px-3">
            <i class="bi-send-fill"></i>
          </button>
        </div>
      </div>
    </div>
  }

</div>