import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  standalone: false,
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.css']
})
export class CameraModalComponent implements OnInit {

  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public deviceId: string = '';
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: any = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  cancelLabel = 'Cancel';
  submitLabel = 'Submit';

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        // console.log(mediaDevices)
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

    this.cancelLabel = this.translate.instant('others.cancel');
    this.submitLabel = this.translate.instant('others.submit');
  }

  closeModalSubmitImage() {
    this.submitLabel = this.translate.instant('clocking.turning-off-camera');
    this.turnOffCamera();
    const payload = {
      status: 'success',
      data: this.imageUrlToFile(this.webcamImage.imageAsDataUrl),
      preview: this.webcamImage.imageAsDataUrl
    }
    setTimeout(() => {
      this.activeModal.close(payload);
      this.submitLabel = this.translate.instant('others.submit');
    }, 1000);
  }

  closeModal() {
    this.turnOffCamera();
    this.cancelLabel = this.translate.instant('clocking.turning-off-camera');
    setTimeout(() => {
      this.activeModal.close();
      this.cancelLabel = this.translate.instant('others.cancel');
    }, 1000);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public resetSnapshot(): void {
    this.webcamImage = null;
    this.showWebcam = true;
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.toggleWebcam();
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
    if (!this.showWebcam) {
      this.turnOffCamera();
    }
  }

  public turnOffCamera(): void {
    this.deviceId = '';
    this.showWebcam = false;
    this.nextWebcam.complete();
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public cameraWasSwitched(deviceId: string): void {
    // console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  imageUrlToFile(data: any) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], 'camera-captured-image.jpeg', { type: 'image/jpeg' })
    return file;
  }

}
