import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from '../../services/utilities/error-handler.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: false,
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  isLoading = false;
  confirmCorrect = true;

  userNotFoundWarning = false;
  passwordStrengthWarning = false;
  passwordConfirmWarning = false;
  view: any = {
    password: false,
    confirmPassword: false
  }

  passwordStrength = {
    minLength: 8,
    symbols: false,
    uppercase: true,
    numbers: true
  }

  noOtpWarning = false;
  resetSuccess = false;

  stepper = 1;

  constructor(
    private authService: AuthService,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private toastrService: ToastrService,
    private translate: TranslateService,
  ) {
    this.translate.use("en");
  }

  ngOnInit(): void {
    if (this.authService.getUser()?.id) {
      this.router.navigate(['home']);
    }
  }

  /**
   * The function `requestOtpCode` sends a request to the server to generate an OTP code and displays a
   * success message if the request is successful, otherwise it handles any errors and displays a
   * warning if the user is not found.
   * @param {string} email - The email parameter is a string that represents the email address of the
   * user.
   */
  async requestOtpCode(username: string) {
    if (username !== '') {
      this.isLoading = true;
      const request = await this.authService.requestOtp(username)
      request.subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.toastrService.success('OTP request sent, please check your email');
          this.stepper = 2;
        },
        error: (e: HttpErrorResponse) => {
          this.isLoading = false;
          // this.errorHandler.handleHttpError(e);
          if (e.error.message.includes('not registered')) this.userNotFoundWarning = true;
        }
      })
    }
  }

  /**
   * The function `submitResetPassword` is used to submit a request to reset a user's password,
   * including validating the password and handling any errors that may occur.
   * @param {string} email - The email parameter.
   * @param {string} otp - The "otp" parameter stands for "One-Time Password". It is a unique code that
   * is generated and sent to the user's email or phone number for verification purposes. The user
   * needs to enter this code along with their email and new password in order to reset their password.
   * @param {string} password - The `password` parameter is a string that represents the new password
   * that the user wants to set for their account.
   * @param {string} confirmPassword - The `confirmPassword` parameter is a string that represents the
   * user's input for confirming their new password. It is used to ensure that the user has entered the
   * same password in both the `password` and `confirmPassword` fields before submitting the password
   * reset request.
   */
  async submitResetPassword(username: string, email: string, otp: string, password: string, confirmPassword: string) {
    if (password !== confirmPassword) this.confirmCorrect = false;
    else {
      this.confirmCorrect = true;
      this.noOtpWarning = false;
      this.passwordStrengthWarning = false;

      this.isLoading = true;
      const payload = {
        username: username,
        email: email,
        otp: otp,
        password: password,
        confirmPassword: confirmPassword
      }

      const reset = await this.authService.resetPassword(payload)
      reset.subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.resetSuccess = true;
          this.toastrService.success('Password reset success, please return to login page and use your newest credential.');
        },
        error: (e: HttpErrorResponse) => {
          this.isLoading = false;
          // this.errorHandler.handleHttpError(e);
          if (e.error.message.length > 0) {
            if (e.error.message[0].includes('not strong enough')) this.passwordStrengthWarning = true;
          }
          if (e.error.message.includes('No otp requested')) this.noOtpWarning = true;
        }
      })
    }
  }

  onNavigateClick(route: string, dashboard = true) {
    if (dashboard) {
      this.router.navigate(['dashboard', route]);
    } else {
      this.router.navigate([route]);
    }
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  checkSpecialChars(str: string) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  checkNumber(str: string) {
    const numbers = /[\d]/;
    return numbers.test(str);
  }

  checkCapital(str: string) {
    const capitals = /[A-Z]/;
    return capitals.test(str);
  }

  checkPasswordStrength(event: any, confirmPassword: string) {
    const passTxt = event.target.value;
    const PASSWORD_REGEX = new RegExp(
      /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/
    );
    if (!PASSWORD_REGEX.test(passTxt))
      this.passwordStrengthWarning = true;
    else this.passwordStrengthWarning = false;

    const check = { target: { value: confirmPassword } }
    this.checkPasswordConfirm(check, event.target.value);
  }

  checkPasswordConfirm(event: any, password: string) {
    if (event.target?.value) {
      const confirmTxt = event.target?.value;
      if (confirmTxt !== password)
        this.passwordConfirmWarning = true;
      else this.passwordConfirmWarning = false;
    }
  }

  toggleViewPassword(type: string) {
    this.view[type] = !this.view[type]
  }

}
